export const PAYMENT_ELEMENT_EXPERIMENT = 'jf-payment-element';
export const PAYMENT_ELEMENT_EXPERIMENT_TABS = 'payment-element-tabs';
export const PAYMENT_ELEMENT_EXPERIMENT_ACCORDION = 'payment-element-accordion';
export const JF_EXPRESS_CHECKOUT = 'jf-express-checkout-v5';
export const ADDRESS_VERIFICATION = 'address-verification-v2';

export const MEMBERSHIP_OPTIONS_EXPERIMENT = 'jf-membership-options';

export const DIRECT_TO_CHECKOUT_TRIALS = 'direct-to-checkout-trials';

export const DIRECT_TO_CHECKOUT_PAID = 'direct-to-checkout-paid';

export const JF_REPLATFORM_ROLLOUT = 'jf-replatform-rollout';
export const JF_REPLATFORM_ROLLOUT_UUID = 'jf-replatform-rollout-uuid';

export const JFRP_EX_NZ_AU = 'jfrp-ex-nz-au-v2';
export const JFRP_EX_GCC = 'jfrp-ex-gcc-v1';
export const JFRP_EX_UK_EU = 'jfrp-ex-uk-eu-v1';
export const JFRP_EX_US_CA = 'jfrp-ex-us-ca-v1';
export const JFRP_EX_ROW = 'jfrp-ex-row-v1';

export const JF_PE_PAYMENT_METHODS = 'jf-pe-payment-methods-v1';
